import React from "react"
import { Link } from "gatsby"
import HELM from "../components/helmet.js"
import Layout from "../components/layout/layout.js"

import NotFoundStyles from "../utilities/pageStyles/404.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeartBroken } from "@fortawesome/free-solid-svg-icons"

export default function NotFound() {
  return (
    <Layout>
      <HELM
        title="404 - Page not found"
        keywords={[`Clothes`, `Teens`, `Plus Size`, `Maternity`]}
      />

      <div className={NotFoundStyles.container}>
        <div className={NotFoundStyles.iconFour}>
          <div className={NotFoundStyles.icon}>
            <FontAwesomeIcon icon={faHeartBroken} />
          </div>
          <h1 className={NotFoundStyles.fourOFour}>404</h1>
        </div>
        <h1 className={NotFoundStyles.heading}>Oh no!</h1>
        <p className={NotFoundStyles.text}>
          The page you are looking for does not seem to exist. Don't worry,
          there are plenty of styles waiting for you!
        </p>
        <Link className={NotFoundStyles.link} to="/">Take me there</Link>
      </div>
    </Layout>
  )
}
